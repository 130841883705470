import type {CryptoCurrencyCode, DecimalString} from '@ncwallet-app/core';
import {
  keepMeaningfulDigitsInCrypto,
  useStrings,
  useTemplates,
  useTheme,
} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {ButtonColor, ButtonVariant, SafeAreaScrollView} from '@ncwallet-app/ui';
import {ArrowUpDownSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {
  ErrorMinAmount,
  ExchangeErrorText,
  ExchangeErrorTextContainer,
  ExchangeInputVariance,
  ExchangeRateText,
  ExchangeSubmit,
  Row,
  SwapCurrenciesText,
  SwapCurrenciesTouchable,
} from '@ncwallet-app/ui/src/components/atoms/exchangeComponents';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import type {ScrollViewProps} from 'react-native';
import {StyleSheet} from 'react-native';

import {useIsSuspended} from '../../Navigation/hooks/useIsSuspended';

export type ExchangeFormScreenProps = BaseSafeAreaProps &
  ScrollViewProps & {
    valueFrom: DecimalString | undefined;
    currencyFrom: CryptoCurrencyCode | undefined;
    valueTo: DecimalString | undefined;
    maxValueTo: DecimalString | undefined;
    maxRestrictedByWalletLimit: boolean;
    maxValueFrom: DecimalString | undefined;
    currencyTo: CryptoCurrencyCode | undefined;
    exchangeRate: DecimalString | undefined;
    swapEnabled: boolean;
    hasValueToError: boolean;
    hasValueFromError: boolean;

    onCurrenciesSwap: () => void;
    onValueFromChange: (from: DecimalString | undefined) => void;
    onValueToChange: (to: DecimalString | undefined) => void;
    onMaxValueToPress: () => void;
    onMaxValueFromPress: () => void;

    onCurrencyFromPress: () => void;
    onCurrencyToPress: () => void;

    error: string | undefined;
    currencyCodeError: CryptoCurrencyCode | undefined;
    minAmount?: string;
    submitEnabled: boolean;
    errorAction?: () => void;

    onSubmit: () => void;
  };

export default observer(function ExchangeFormScreen(
  props: ExchangeFormScreenProps,
) {
  const {
    currencyFrom,
    maxValueTo,
    maxValueFrom,
    currencyTo,
    minAmount,
    valueFrom,
    onValueFromChange,
    valueTo,
    onValueToChange,
    exchangeRate,
    onSubmit,
    hasValueFromError,
    onMaxValueFromPress,
    onCurrencyFromPress,
    swapEnabled,
    onCurrenciesSwap,
    hasValueToError,
    onMaxValueToPress,
    errorAction,
    onCurrencyToPress,
    error,
    currencyCodeError,
    submitEnabled,
    ...rest
  } = props;
  const theme = useTheme();
  const strings = useStrings();
  const templates = useTemplates();

  const ArrowUpDownColored = useCallback(
    () => <ArrowUpDownSvg color={theme.palette.info} />,
    [theme],
  );

  const rootStyle = useMemo(() => ({width: '100%'}) as const, []);
  const isSuspended = useIsSuspended();
  return (
    <SafeAreaScrollView
      style={rootStyle}
      contentContainerStyle={styles.container}
      {...rest}>
      <Row>
        <ExchangeInputVariance
          maxValue={maxValueFrom}
          maxRestrictedByWalletLimit={props.maxRestrictedByWalletLimit}
          error={hasValueFromError}
          placeholder={strings['exchangeCrypto.from.placeholder']}
          value={valueFrom}
          onChange={onValueFromChange}
          crypto={currencyFrom}
          onMaxPress={onMaxValueFromPress}
          onSelectButtonPress={onCurrencyFromPress}
        />
      </Row>

      {swapEnabled && (
        <SwapCurrenciesTouchable onPress={onCurrenciesSwap}>
          <ArrowUpDownColored />
          <SwapCurrenciesText>
            {strings['exchangeCrypto.swap']}
          </SwapCurrenciesText>
        </SwapCurrenciesTouchable>
      )}

      <Row>
        <ExchangeInputVariance
          maxValue={maxValueTo}
          maxRestrictedByWalletLimit={props.maxRestrictedByWalletLimit}
          error={hasValueToError}
          label={strings['exchangeCrypto.to.label']}
          placeholder={strings['exchangeCrypto.to.placeholder']}
          value={valueTo}
          onChange={onValueToChange}
          crypto={currencyTo}
          onMaxPress={onMaxValueToPress}
          onSelectButtonPress={onCurrencyToPress}
        />
      </Row>

      {exchangeRate && (
        <ExchangeRateText>
          {templates['exchangeCrypto.rate']({
            rate: `${keepMeaningfulDigitsInCrypto(exchangeRate)} ${currencyTo ?? ''}`,
          })}
        </ExchangeRateText>
      )}
      <ExchangeErrorTextContainer heightAuto>
        <ExchangeErrorText> {error}</ExchangeErrorText>
        {errorAction && minAmount && (
          <ErrorMinAmount onPress={errorAction}>
            <ExchangeErrorText>
              {minAmount} {currencyCodeError}
            </ExchangeErrorText>
          </ErrorMinAmount>
        )}
      </ExchangeErrorTextContainer>
      <ExchangeSubmit
        title={strings['exchangeCrypto.submit']}
        onPress={onSubmit}
        variant={ButtonVariant.Highlighted}
        color={ButtonColor.Secondary}
        disabled={isSuspended || !submitEnabled || !!error}
      />
    </SafeAreaScrollView>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingHorizontal: 15,
  },

  offsetTop: {
    marginTop: 'auto',
  },
});
