import {variance} from '@ncwallet-app/core';
import type {LgProfileEmailButtonProps} from '@ncwallet-app/core/src/Components/LgProfileEmailButton';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {TrashSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

const LgProfileEmailButton = observer(
  ({
    email,
    onDeleteAccountPress,
    onLinkToEmailPress,
  }: LgProfileEmailButtonProps) => {
    const handleEmailPress = useCallback(() => {
      if (email) {
        return;
      }
      onLinkToEmailPress();
    }, [email, onLinkToEmailPress]);

    return (
      <DisabledValue smallPadding>
        <TextWrapper onPress={handleEmailPress}>
          <DisabledText>{email || '-'}</DisabledText>
        </TextWrapper>
        <DeleteButton onPress={onDeleteAccountPress}>
          <TrashIcon />
        </DeleteButton>
      </DisabledValue>
    );
  },
);

export default LgProfileEmailButton;

const TrashIcon = variance(TrashSvg)(
  () => ({root: {}}),
  theme => ({
    color: theme.palette.icon1,
    width: 22,
    height: 22,
  }),
);

const DisabledValue = variance(View)(theme => ({
  root: {
    padding: 15,
    borderRadius: 6,
    backgroundColor: theme.palette.uiPrimary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  smallPadding: {
    paddingVertical: 5,
    paddingRight: 5,
  },
}));

const TextWrapper = variance(TouchableOpacity)(() => ({
  root: {
    flex: 1,
  },
}));

const DisabledText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.uiMain,
    fontSize: 16,
  },
  error: {
    color: theme.palette.error,
  },
}));

const DeleteButton = variance(TouchableOpacity)(() => ({
  root: {
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    minWidth: 30,
  },
}));
