import type {
  Core,
  JsonKeyValueMap,
  JsonKeyValueStore,
  JsonSecureKeyValueMap,
  KeyValueMap,
  KeyValueStore,
  Root,
  SecureKeyValueMap,
  Service,
} from '@ncwallet-app/core';
import {
  BaseRootService,
  batchDisposers,
  DeepLinkingLocationSourceService,
  HttpFactory,
  InstallReferrerIdentificationService,
  JsonKeyValueStoreImpl,
  KeyValueStoreImpl,
  StubBiometricSettingsManager,
  StubBiometricsService,
  StubBiometricStateHelperImpl,
  StubBootSplashImpl,
} from '@ncwallet-app/core';
import AdvertisingIdentifierSubImpl from '@ncwallet-app/core/src/AdvertisingIdentifier/AdvertisingIdentifierSubImpl';
import {CommonAppViewportImpl} from '@ncwallet-app/core/src/AppViewport';
import CommonCameraImpl from '@ncwallet-app/core/src/Camera/CommonCameraImpl';
import {UniversalDeviceInfoImpl} from '@ncwallet-app/core/src/DeviceInfo';
import {WebDeviceScreenService} from '@ncwallet-app/core/src/DeviceScreenState';
import {StubHapticsImpl} from '@ncwallet-app/core/src/Haptics';
import {StubManualTestHelperImpl} from '@ncwallet-app/core/src/ManualTestHelper';
import {StubPushAdvert} from '@ncwallet-app/core/src/PushAdvert';
import {CommonQrCodeScannerImpl} from '@ncwallet-app/core/src/QrCodeScanner';
import {SentryLogService} from '@ncwallet-app/core/src/SentryLog';
import {StubTelegramCredentialsProviderImpl} from '@ncwallet-app/core/src/TelegramCredentialsProvider';

import {TelegramLocalAuthClientService} from '../Auth';
import {TelegramPlatformSpecificComponents} from '../Components/TelegramPlatformSpecificComponentsImpl';
import {TelegramExpiredTokenRecoveryImpl} from '../ExpiredTokenRecovery';
import {parseInstallReferrer} from '../InstallReferrerIdentification';
import {LinkToEmailServiceImpl} from '../LinkToEmailService';
import {TelegramLocationImpl} from '../Location';
import {StubAppleOAuth2ProviderService} from '../OAuth2Provider';
import {Sentry} from '../Sentry';
import {TelegramSessionContextProviderImpl} from '../SessionContext';
import {SharingImpl} from '../Sharing';
import {TelegramAlertImpl} from '../TelegramAlert';
import {TelegramDebugLocationDetectorService} from '../TelegramDebugLocationDetectorService';
import {TelegramMiniAppService} from '../TelegramMiniApp';
import {TelegramTransactionReportImpl} from '../TransactionReport';

export default class TelegramRootService
  extends BaseRootService
  implements Root, Service
{
  constructor(protected readonly _core: Core) {
    super(_core);
  }

  readonly installReferrerIdentification =
    new InstallReferrerIdentificationService(this, parseInstallReferrer);
  readonly alert = new TelegramAlertImpl();
  private readonly _httpFactory = new HttpFactory(this);
  readonly http = this._httpFactory.create();
  readonly deviceInfo = new UniversalDeviceInfoImpl();
  readonly keyValueStore = new KeyValueStoreImpl<KeyValueMap>(this);
  readonly jsonKeyValueStore = new JsonKeyValueStoreImpl(
    this,
    this.keyValueStore as KeyValueStore<JsonKeyValueMap>,
  );
  readonly secureKeyValueStore = this
    .keyValueStore as unknown as KeyValueStore<SecureKeyValueMap>;
  readonly jsonSecureKeyValueStore = this
    .jsonKeyValueStore as unknown as JsonKeyValueStore<JsonSecureKeyValueMap>;
  readonly location = new TelegramLocationImpl(this);
  readonly telegramMiniApp = new TelegramMiniAppService(this);
  readonly linkToEmailService = new LinkToEmailServiceImpl(this);
  readonly locationSource = new DeepLinkingLocationSourceService(this);
  readonly appleOAuth2Provider = new StubAppleOAuth2ProviderService();
  readonly biometrics = new StubBiometricsService();
  readonly biometricSettingsManager = new StubBiometricSettingsManager();
  readonly biometricStateHelper = new StubBiometricStateHelperImpl();
  readonly bootSplash = new StubBootSplashImpl();
  readonly manualTestHelper = new StubManualTestHelperImpl();
  readonly haptics = new StubHapticsImpl();
  readonly sharing = new SharingImpl(this);
  readonly sessionContextProvider = new TelegramSessionContextProviderImpl();
  readonly advertisingIdentifier = new AdvertisingIdentifierSubImpl();
  readonly sentryLog = new SentryLogService(this, Sentry);
  readonly camera = new CommonCameraImpl();
  readonly platformSpecificComponents =
    new TelegramPlatformSpecificComponents();
  readonly deviceScreenState = new WebDeviceScreenService();
  readonly expiredTokenRecovery = new TelegramExpiredTokenRecoveryImpl(this);
  readonly debugLocationDetector = new TelegramDebugLocationDetectorService(
    this,
  );
  readonly authClient = new TelegramLocalAuthClientService(this);
  readonly authHelper = this.authClient;
  readonly telegramCredentialsProvider =
    new StubTelegramCredentialsProviderImpl();
  readonly transactionReport = new TelegramTransactionReportImpl(this);
  readonly qrCodeScanner = new CommonQrCodeScannerImpl(this);
  readonly appViewport = new CommonAppViewportImpl(this);
  readonly pushAdvert = new StubPushAdvert();

  subscribe() {
    return batchDisposers(
      super.subscribe(),
      this.authClient.subscribe(),
      this.installReferrerIdentification.subscribe(),
      this.debugLocationDetector.subscribe(),
      this.sentryLog.subscribe(),
      this.deviceScreenState.subscribe(),
      this.telegramMiniApp.subscribe(),
      this.linkToEmailService.subscribe(),
    );
  }
}
