import {useRoot, variance} from '@ncwallet-app/core';
import {useTabBarLayout} from '@ncwallet-app/navigation/src/components';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Input,
  InputType,
} from '@ncwallet-app/ui';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import {useTelegramStartParam} from './useTelegramStartParam';

export type DebugScreenProps = {
  goToLogs: () => void;
  goToSignOutReasonLogs: () => void;
  goToEnvironmentList: () => void;
  goToEnvironmentForm: () => void;
  getBiometricsEnabled: () => boolean;
  toggleBiometrics: () => void;
  onRefreshTokenSet: (pin: string, token: string, deviceId: string) => void;
  onNativeCrashPress: () => void;
  onSignOut: () => void;
  onShowDemoMessage: () => void;
  copyLog: () => void;
  shareLog: () => void;
  saveLog: () => void;
  clearLocalStorage: () => void;
  clearSessionStorage: () => void;
  clearAsyncStorage: () => Promise<void>;
  clearSecureStorage: () => Promise<void>;
};

export default observer(function DebugScreen(props: DebugScreenProps) {
  const {
    goToLogs,
    goToSignOutReasonLogs,
    goToEnvironmentList,
    goToEnvironmentForm,
    onRefreshTokenSet,
    onNativeCrashPress,
    onShowDemoMessage,
    copyLog,
    shareLog,
    saveLog,
    clearLocalStorage,
    clearAsyncStorage,
    clearSessionStorage,
    clearSecureStorage,
  } = props;
  const root = useRoot();
  const [installReferrer, setInstallReferrer] = useState('');
  const {authHelper, debug, installReferrerIdentification, alert} = root;
  const telegramStartParam = useTelegramStartParam();
  const signOut = useCallback(
    () => authHelper.signOut({reason: 'DebugScreen'}),
    [authHelper],
  );
  const layout = useTabBarLayout();

  const [pin, setPin] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [deviceId, setDeviceId] = useState('');

  const onRefreshTokenSetPress = useCallback(() => {
    onRefreshTokenSet(pin, refreshToken, deviceId);
  }, [onRefreshTokenSet, pin, refreshToken, deviceId]);

  const raiseInternalError = useCallback(
    () => root.ncWalletJsonRpcClient.call('error', undefined),
    [root],
  );

  const [localPin, setLocalPin] = useState('');

  useEffect(() => {
    try {
      void installReferrerIdentification
        .getInstallReferrer()
        .then(ref => {
          if (ref) {
            setInstallReferrer(ref);
          }
        })
        .catch(() => {});
    } catch {
      /* empty */
    }
  }, [installReferrerIdentification]);

  const copyInstallReferrer = useCallback(async () => {
    await setStringAsync(installReferrer);
    alert.alert(
      'Success',
      'The value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [alert, installReferrer]);

  const copyTelegramStartParam = useCallback(async () => {
    await setStringAsync(telegramStartParam);
    alert.alert(
      'Success',
      'Telegram start_param value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [alert, telegramStartParam]);

  return (
    <Root
      contentContainerStyle={[
        styles.container,
        {paddingBottom: layout.height},
      ]}>
      <Section>
        <Button
          title={debug.logEnabled ? 'Disable logs' : 'Enable logs'}
          onPress={debug.toggleLog}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Text}
        />
        <Button title="COPY LOG" onPress={copyLog} />
        <Button
          title={`Install referrer: ${installReferrer ? installReferrer : '-'}`}
          onPress={copyInstallReferrer}
        />
        <Button disabled title="SHARE LOG" onPress={shareLog} />
        <Button disabled title="SAVE LOG" onPress={saveLog} />
        <Button
          title="Logs"
          onPress={goToLogs}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Text}
        />
        <Button
          title="Logs for sign out bug"
          onPress={goToSignOutReasonLogs}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Text}
        />

        <Button title="Create env" onPress={goToEnvironmentForm} />
        <Button title="Switch env" onPress={goToEnvironmentList} />
        <Button title="NATIVE CRASH" onPress={onNativeCrashPress} />
      </Section>

      <BiometricsSection {...props} />

      <Section>
        <Input
          value={pin}
          onChangeText={setPin}
          placeholder="pin"
          type={InputType.Numeric}
        />

        <Input
          value={refreshToken}
          onChangeText={setRefreshToken}
          placeholder="refresh token"
        />
        <Input
          value={deviceId}
          onChangeText={setDeviceId}
          placeholder="device id"
        />

        <Button
          title="Set credentials"
          onPress={onRefreshTokenSetPress}
          color={ButtonColor.Error}
        />
        <Button title="Show flash message" onPress={onShowDemoMessage} />
      </Section>

      <Section>
        <Input
          value={localPin}
          onChangeText={setLocalPin}
          placeholder="Local Pin"
          type={InputType.Numeric}
        />
      </Section>

      <Section>
        <Button
          title="Sign out"
          onPress={signOut}
          color={ButtonColor.Error}
          variant={ButtonVariant.Text}
        />
      </Section>
      <Section>
        <Button
          title="Raise Internal error"
          onPress={raiseInternalError}
          variant={ButtonVariant.Danger}
        />
      </Section>
      <Section>
        <Button
          title="Disable debug"
          onPress={debug.disableDebug}
          variant={ButtonVariant.Danger}
        />
        {Platform.OS === 'web' ? (
          <>
            <Button
              title="Clear localStorage"
              onPress={clearLocalStorage}
              variant={ButtonVariant.Danger}
            />
            <Button
              title="Clear sessionStorage"
              onPress={clearSessionStorage}
              variant={ButtonVariant.Danger}
            />
          </>
        ) : (
          <>
            <Button
              title="Clear AsyncStorage"
              onPress={clearAsyncStorage}
              variant={ButtonVariant.Danger}
            />
            <Button
              title="Clear SecureStorage"
              onPress={clearSecureStorage}
              variant={ButtonVariant.Danger}
            />
          </>
        )}
      </Section>
      <Section>
        <Input value={telegramStartParam} placeholder="Telegram start_param" />
        <Button
          title={'Copy Telegram start_param'}
          onPress={copyTelegramStartParam}
          disabled={telegramStartParam === ''}
        />
      </Section>
    </Root>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 20,
  },
});

const Root = variance(ScrollView)(theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
  },
}));

const Section = variance(View)(theme => ({
  root: {
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.foreground,
    backgroundColor: theme.palette.background,
    gap: 10,
  },
}));

type BiometricsSectionProps = {
  getBiometricsEnabled: () => boolean;
  toggleBiometrics: () => void;
};

const BiometricsSection = observer((props: BiometricsSectionProps) => {
  const {getBiometricsEnabled, toggleBiometrics} = props;
  const enabled = getBiometricsEnabled();
  return (
    <Section>
      <Button
        title={enabled ? 'Disable biometrics' : 'Enable biometrics'}
        onPress={toggleBiometrics}
        color={enabled ? ButtonColor.Error : ButtonColor.Success}
      />
    </Section>
  );
});
