import type {
  CurrenciesRateHistoryItem,
  DecimalString,
  FiatCurrencyCode,
} from '@ncwallet-app/core';
import {
  keepMeaningfulDigitsInCrypto,
  useStrings,
  useTemplates,
  useTheme,
} from '@ncwallet-app/core';
import {BaseSkeleton, ButtonColor, ButtonVariant} from '@ncwallet-app/ui/src';
import {ExchangeAltSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {ArrowUpDownSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {
  Container,
  ErrorMinAmount,
  ExchangeErrorText,
  ExchangeErrorTextContainer,
  ExchangeInputVariance,
  ExchangeRateText,
  ExchangeSubmit,
  Inner,
  Row,
  ScreenTitleText,
  ScreenTitleView,
  SizedView,
  SwapCurrenciesEmpty,
  SwapCurrenciesText,
  SwapCurrenciesTouchable,
  TopRow,
} from '@ncwallet-app/ui/src/components/atoms/exchangeComponents';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useIsSuspended} from '../../Navigation/hooks/useIsSuspended';
import {EXTRA_BOTTOM_OFFSET} from '../constants';
import CurrencyGraph from './CurrencyGraph';
import type {ExchangeFormScreenProps} from './ExchangeFormScreen';

export type ExchangeFormWideScreenProps = ExchangeFormScreenProps & {
  currencyFromRateHistory: CurrenciesRateHistoryItem[] | undefined;
  currencyToRateHistory: CurrenciesRateHistoryItem[] | undefined;
  currencyToToFiatRate: DecimalString | undefined;
  currencyFromToFiatRate: DecimalString | undefined;
  isGraphsDataLoaded: boolean;
  currencyFromDiff: DecimalString | undefined;
  currencyToDiff: DecimalString | undefined;
  baseFiat: FiatCurrencyCode | undefined;
};

export default observer(function ExchangeFormWideScreen(
  props: ExchangeFormWideScreenProps,
) {
  const {
    currencyFrom,
    maxValueTo,
    minAmount,
    maxValueFrom,
    maxRestrictedByWalletLimit,
    currencyTo,
    valueFrom,
    onValueFromChange,
    valueTo,
    onValueToChange,
    errorAction,
    exchangeRate,
    onSubmit,
    baseFiat,
    currencyFromRateHistory,
    currencyToRateHistory,
    currencyFromDiff,
    currencyToDiff,
    ...rest
  } = props;
  const theme = useTheme();
  const strings = useStrings();
  const templates = useTemplates();
  const isSuspended = useIsSuspended();
  const ArrowUpDownColored = useCallback(
    () => <ArrowUpDownSvg color={theme.palette.info} />,
    [theme],
  );

  return (
    <Container
      {...rest}
      contentContainerStyle={{flexGrow: 1, paddingBottom: 10}}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      <Inner>
        <TopRow>
          <ScreenTitleView>
            <ExchangeAltSvg color={theme.palette.foreground} />
            <ScreenTitleText>{strings['screenTitle.change']}</ScreenTitleText>
          </ScreenTitleView>

          {isNil(exchangeRate) ? (
            <BaseSkeleton width={100} height={15} />
          ) : (
            <ExchangeRateText>
              {templates['exchangeCrypto.rate']({
                rate: `${keepMeaningfulDigitsInCrypto(
                  exchangeRate,
                )} ${currencyTo ?? ''}`,
              })}
            </ExchangeRateText>
          )}
        </TopRow>

        <Row>
          <ExchangeInputVariance
            maxValue={maxValueFrom}
            maxRestrictedByWalletLimit={maxRestrictedByWalletLimit}
            error={props.hasValueFromError}
            label={''}
            placeholder={strings['exchangeCrypto.from.placeholder']}
            value={valueFrom}
            onChange={onValueFromChange}
            crypto={currencyFrom}
            onMaxPress={props.onMaxValueFromPress}
            onSelectButtonPress={props.onCurrencyFromPress}
            direction="from"
          />
          <SizedView />
          <CurrencyGraph
            isLoaded={props.isGraphsDataLoaded}
            rate={props.currencyFromToFiatRate}
            rateHistory={currencyFromRateHistory}
            baseFiat={baseFiat}
            currencyGraphDiff={currencyFromDiff}
          />
        </Row>

        {props.swapEnabled ? (
          <SwapCurrenciesTouchable onPress={props.onCurrenciesSwap}>
            <ArrowUpDownColored />
            <SwapCurrenciesText>
              {strings['exchangeCrypto.swap']}
            </SwapCurrenciesText>
          </SwapCurrenciesTouchable>
        ) : (
          <SwapCurrenciesEmpty />
        )}

        <Row>
          <ExchangeInputVariance
            maxValue={maxValueTo}
            maxRestrictedByWalletLimit={maxRestrictedByWalletLimit}
            error={props.hasValueToError}
            label={strings['exchangeCrypto.to.label']}
            placeholder={strings['exchangeCrypto.to.placeholder']}
            value={valueTo}
            onChange={onValueToChange}
            crypto={currencyTo}
            onMaxPress={props.onMaxValueToPress}
            onSelectButtonPress={props.onCurrencyToPress}
            direction="to"
          />

          <SizedView />
          <CurrencyGraph
            isLoaded={props.isGraphsDataLoaded}
            rate={props.currencyToToFiatRate}
            rateHistory={currencyToRateHistory}
            baseFiat={baseFiat}
            currencyGraphDiff={currencyToDiff}
          />
        </Row>

        <ExchangeErrorTextContainer>
          <ExchangeErrorText>{props.error}</ExchangeErrorText>
          {errorAction && minAmount && (
            <ErrorMinAmount
              onPress={errorAction}
              testID="exchange-mim-amount-btn">
              <ExchangeErrorText>
                {minAmount} {props.currencyCodeError}
              </ExchangeErrorText>
            </ErrorMinAmount>
          )}
        </ExchangeErrorTextContainer>
        <ExchangeSubmit
          title={strings['exchangeCrypto.submit']}
          onPress={onSubmit}
          variant={ButtonVariant.Highlighted}
          color={ButtonColor.Secondary}
          disabled={isSuspended || !props.submitEnabled || !!props.error}
          testID="exchange-submit-btn"
        />
      </Inner>
    </Container>
  );
});
