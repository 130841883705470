import type {Url} from '@ncwallet-app/core';
import {
  formatCryptoValue,
  keepMeaningfulDigitsInCrypto,
  shortenWalletAddress,
  useRoot,
  useStrings,
  useTheme,
} from '@ncwallet-app/core';
import {DetailsTransactionFields} from '@ncwallet-app/core/src/NCWalletServer/WalletsTransactions';
import {useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {FC} from 'react';
import React, {useCallback} from 'react';

import type {DetailsProps} from './Details';
import HistoryDetailsComment from './HistoryDetailsComment';
import {
  CopyIcon,
  DetailsIcon,
  DetailsLabel,
  DetailsRow,
  DetailsValue,
  ShareIcon,
} from './sharedComponents';

const Rate = observer(
  ({rate, isLast}: Pick<DetailsProps, 'rate' | 'isLast'>) => {
    const strings = useStrings();
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>{strings['historyDetailsScreen.rate']}:</DetailsLabel>
        <DetailsValue>
          {rate && keepMeaningfulDigitsInCrypto(rate)}
        </DetailsValue>
      </DetailsRow>
    );
  },
);

const WalletFee = observer(
  ({
    walletFee,
    infoCurrency,
    isLast,
    cryptoFractionDigits,
  }: Pick<
    DetailsProps,
    'walletFee' | 'infoCurrency' | 'isLast' | 'cryptoFractionDigits'
  >) => {
    const strings = useStrings();
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>
          {strings['historyDetailsScreen.walletFee']}:
        </DetailsLabel>
        <DetailsValue>
          {formatCryptoValue(walletFee ?? '0', cryptoFractionDigits)}{' '}
          {infoCurrency}
        </DetailsValue>
      </DetailsRow>
    );
  },
);

const MinerFee = observer(
  ({
    minerFee,
    infoCurrency,
    isLast,
    cryptoFractionDigits,
  }: Pick<
    DetailsProps,
    'minerFee' | 'infoCurrency' | 'isLast' | 'cryptoFractionDigits'
  >) => {
    const strings = useStrings();
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>{strings['historyDetailsScreen.minerFee']}:</DetailsLabel>
        <DetailsValue>
          {formatCryptoValue(minerFee ?? '0', cryptoFractionDigits)}{' '}
          {infoCurrency}
        </DetailsValue>
      </DetailsRow>
    );
  },
);

const Comment = observer(
  ({comment, isLast}: Pick<DetailsProps, 'comment' | 'isLast'>) => {
    const strings = useStrings();
    if (!comment) {
      return null;
    }

    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>
          {strings['historyDetailsScreen.memoComment']}:
        </DetailsLabel>
        <HistoryDetailsComment comment={comment} />
      </DetailsRow>
    );
  },
);

const Network = observer(
  ({infoNetwork, isLast}: Pick<DetailsProps, 'infoNetwork' | 'isLast'>) => {
    const strings = useStrings();
    if (!infoNetwork) {
      return null;
    }

    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>{strings['historyDetailsScreen.network']}:</DetailsLabel>
        <DetailsValue>{infoNetwork}</DetailsValue>
      </DetailsRow>
    );
  },
);

const Currency = observer(
  ({infoCurrency, isLast}: Pick<DetailsProps, 'infoCurrency' | 'isLast'>) => {
    const strings = useStrings();
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>
          {strings['historyDetailsScreen.addressCurrency']}:
        </DetailsLabel>
        <DetailsValue>{infoCurrency}</DetailsValue>
      </DetailsRow>
    );
  },
);

const TxId = observer(
  ({
    txId,
    onCopy,
    isLast,
    blockchainNetwork,
    isInternal,
  }: Pick<
    Partial<DetailsProps>,
    'txId' | 'onCopy' | 'isLast' | 'blockchainNetwork' | 'isInternal'
  >) => {
    const strings = useStrings();
    const theme = useTheme();
    const isMd = useIsDimensions('md');
    const {location} = useRoot();

    const goToExplorer = useCallback(() => {
      void location.open(
        `${blockchainNetwork?.explorer_url ?? ''}${txId ?? ''}`.trim() as Url,
      );
    }, [location, blockchainNetwork, txId]);

    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>{strings['historyDetailsScreen.txid']}:</DetailsLabel>
        <DetailsValue>
          {txId ? shortenWalletAddress(txId, isMd ? 25 : 10) : '-'}
        </DetailsValue>
        {txId && (
          <DetailsIcon onPress={() => onCopy?.(txId)}>
            <CopyIcon color={theme.palette.uiSecondary} />
          </DetailsIcon>
        )}
        {txId &&
          !isInternal &&
          blockchainNetwork &&
          blockchainNetwork.explorer_url && (
            <DetailsIcon onPress={goToExplorer}>
              <ShareIcon color={theme.palette.uiSecondary} />
            </DetailsIcon>
          )}
      </DetailsRow>
    );
  },
);

const FromAddress = observer(
  ({
    fromAddress,
    onCopy,
    isLast,
  }: Pick<Partial<DetailsProps>, 'fromAddress' | 'onCopy' | 'isLast'>) => {
    const strings = useStrings();
    const theme = useTheme();
    const isMd = useIsDimensions('md');
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>
          {strings['historyDetailsScreen.senderAddress']}:
        </DetailsLabel>
        <DetailsValue>
          {fromAddress
            ? !isMd
              ? shortenWalletAddress(fromAddress, 10)
              : fromAddress
            : '-'}
        </DetailsValue>
        {fromAddress && (
          <DetailsIcon onPress={() => onCopy?.(fromAddress)}>
            <CopyIcon color={theme.palette.uiSecondary} />
          </DetailsIcon>
        )}
      </DetailsRow>
    );
  },
);

const ToAddress = observer(
  ({
    address,
    onCopy,
    isLast,
  }: Pick<Partial<DetailsProps>, 'address' | 'onCopy' | 'isLast'>) => {
    const strings = useStrings();
    const theme = useTheme();
    const isMd = useIsDimensions('md');
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>{strings['historyDetailsScreen.address']}:</DetailsLabel>
        <DetailsValue>
          {address
            ? !isMd
              ? shortenWalletAddress(address, 10)
              : address
            : '-'}
        </DetailsValue>
        {address && (
          <DetailsIcon onPress={() => onCopy?.(address)}>
            <CopyIcon color={theme.palette.uiSecondary} />
          </DetailsIcon>
        )}
      </DetailsRow>
    );
  },
);

const ToCurrency = observer(
  ({
    infoToCurrency,
    isLast,
  }: Pick<DetailsProps, 'infoToCurrency' | 'isLast'>) => {
    const strings = useStrings();
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>
          {strings['historyDetailsScreen.toCurrency']}:
        </DetailsLabel>
        <DetailsValue>{infoToCurrency}</DetailsValue>
      </DetailsRow>
    );
  },
);

const FromCurrency = observer(
  ({
    infoFromCurrency,
    isLast,
  }: Pick<DetailsProps, 'infoFromCurrency' | 'isLast'>) => {
    const strings = useStrings();
    return (
      <DetailsRow last={isLast}>
        <DetailsLabel>
          {strings['historyDetailsScreen.fromCurrency']}:
        </DetailsLabel>
        <DetailsValue>{infoFromCurrency}</DetailsValue>
      </DetailsRow>
    );
  },
);

// eslint-disable-next-line import-x/prefer-default-export
export const DetailsComponentMap: {
  [k in DetailsTransactionFields]: FC<Partial<DetailsProps>>;
} = {
  [DetailsTransactionFields.Rate]: Rate,
  [DetailsTransactionFields.WalletFee]: WalletFee,
  [DetailsTransactionFields.MinerFee]: MinerFee,
  [DetailsTransactionFields.Comment]: Comment,
  [DetailsTransactionFields.Network]: Network,
  [DetailsTransactionFields.Currency]: Currency,
  [DetailsTransactionFields.TxId]: TxId,
  [DetailsTransactionFields.FromAddress]: FromAddress,
  [DetailsTransactionFields.ToCurrency]: ToCurrency,
  [DetailsTransactionFields.FromCurrency]: FromCurrency,
  [DetailsTransactionFields.ToAddress]: ToAddress,
};
